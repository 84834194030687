export const galleryItems = {
  gallery: [
    { src: '/assets/evening1', title: 'Outside/Pool', caption: 'Outside', category: 'outside' },
    { src: '/assets/evening2', title: 'Outside/Dining', caption: '', category: 'outside' },
    { src: '/assets/evening3', title: 'Outside/Dining', caption: '', category: 'outside' },
    { src: '/assets/pool_area3', title: 'Outseide/Pool', caption: '', category: 'outside' },
    { src: '/assets/lounge1', title: 'Kitchen/Lounge', caption: '', category: 'kitchen' },
    { src: '/assets/lounge2', title: 'Kitchen/Lounge', caption: '', category: 'kitchen' },
    { src: '/assets/lounge3', title: 'Kitchen/Lounge', caption: '', category: 'kitchen' },
    { src: '/assets/lounge4', title: 'Kitchen/Lounge', caption: '', category: 'kitchen' },
    { src: '/assets/lounge5', title: 'Kitchen/Lounge', caption: '', category: 'kitchen' },
    { src: '/assets/kitchen', title: 'Kitchen', caption: '', category: 'kitchen' },
    { src: '/assets/bed1_1', title: 'Master Bedroom', caption: '', category: 'bedrooms' },
    { src: '/assets/bed1_2', title: 'Master Bedroom', caption: '', category: 'bedrooms' },
    { src: '/assets/bed1_3', title: 'Master Bedroom', caption: '', category: 'bedrooms' },
    { src: '/assets/master_bedroom_ensuite', title: 'Master Bedroom ensuite', caption: '', category: 'bedrooms' },
    { src: '/assets/bedroom2', title: 'Bedroom 2', caption: '', category: 'bedrooms' },
    { src: '/assets/bedroom2_2', title: 'Bedroom 2', caption: '', category: 'bedrooms' },
    { src: '/assets/bedroom2_ensuite', title: 'Bedroom 2 ensuite', caption: '', category: 'bedrooms' },
    { src: '/assets/bedroom3', title: 'Bedroom 3', caption: '', category: 'bedrooms' },
    { src: '/assets/bedroom3_1', title: 'Bedroom 3', caption: '', category: 'bedrooms' },
    { src: '/assets/downstairs_chil_area3', title: 'Courtyard', caption: '', category: 'outside' },
    { src: '/assets/downstairs_chil_area4', title: 'Courtyard/Master Bedroom', caption: '', category: 'outside' },
    { src: '/assets/pool1', title: 'Terrace', caption: '', category: 'outside' },
  ],
};
