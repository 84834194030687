import React, { FC } from 'react';

import * as CSS from './gallery.styles';

const getItems = (items: CarouselItem[], size: string, category: string) => {
  const filteredItems = items.filter((item) => item.category === category || category === 'all');
  return filteredItems.map((item, index) => {
    return (
      <CSS.CarouselItem key={`item-${index}`}>
        <img src={`${item.src}_${size}.jpg`} alt={item.title} />
        <CSS.CarouselCaption>
          <div>{`${index + 1}/${filteredItems.length}`}</div>
          <div style={{ marginRight: '45px' }}>{item.title}</div>
        </CSS.CarouselCaption>
      </CSS.CarouselItem>
    );
  });
};
type CarouselItem = {
  src: string;
  title: string;
  caption: string;
  category: string;
};
type CarouselContainerProps = {
  carouselItems: CarouselItem[];
  size: string;
  category: string;
};

const CarouselContainer: FC<CarouselContainerProps> = ({ carouselItems, size, category = 'all' }) => (
  <CSS.CarouselContainer>
    <div>{getItems(carouselItems, size, category)}</div>
  </CSS.CarouselContainer>
);

export default CarouselContainer;
