import { useQuery } from '@tanstack/react-query';

type EnquiryResponse = {
  success: boolean;
};
const sendEnquiry = async (email: string, name: string, message: string): Promise<EnquiryResponse> => {
  const response = await fetch('https://www.kalkanpad.com/enquiry', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, name, message }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return { success: true };
};

export const useSendEnquiry = (email: string, name: string, message: string, dataValid: boolean) => {
  const signIn = useQuery({
    queryKey: ['sendEmail', { email }],
    queryFn: () => sendEnquiry(email, name, message),
    enabled: dataValid,
    retry: false,
  });
  return signIn;
};
