import React, { FC } from 'react';
import Day from './day';
import { createCalendarMonth } from '../../utils/availability-utils';

import * as CSS from './availability.styles';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
type MonthProps = {
  daysBooked: number[];
  month: number;
  year: number;
  setAvailability?: (day: number, month: number, year: number) => void;
};

const Month: FC<MonthProps> = ({ daysBooked, month, year, setAvailability }) => {
  const weeks = createCalendarMonth(month, year, daysBooked);
  return (
    <CSS.CarouselItem>
      <CSS.MonthContainer key={`${year}-${monthNames[month - 1]}`}>
        <tbody>
          <tr>
            <th colSpan={7}>{`${monthNames[month - 1]} ${year}`}</th>
          </tr>
          <tr>
            <CSS.DayTitle>Sun</CSS.DayTitle>
            <CSS.DayTitle>Mon</CSS.DayTitle>
            <CSS.DayTitle>Tue</CSS.DayTitle>
            <CSS.DayTitle>Wed</CSS.DayTitle>
            <CSS.DayTitle>Thu</CSS.DayTitle>
            <CSS.DayTitle>Fri</CSS.DayTitle>
            <CSS.DayTitle>Sat</CSS.DayTitle>
          </tr>
          {weeks.map((week, index) => {
            return (
              <tr key={`week-${index}`}>
                {week.map((day, index) => {
                  return (
                    <Day
                      key={`${month}-${year}-${index}`}
                      availabilty={day.status}
                      day={day.day}
                      year={year}
                      month={month}
                      setAvailability={day.status !== 'c' && setAvailability ? setAvailability : undefined}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </CSS.MonthContainer>
    </CSS.CarouselItem>
  );
};

export default Month;
