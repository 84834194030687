import React, { FC, useEffect, useState } from 'react';
import { CommonPageContent, PageContainer, CommonPageHeader } from '../common-styles/common-styles';
import { FormWrapper } from '../contact-us/contact-us.styles';
import { useSignInQuery } from '../../api/sign-in';

type LoginProps = {
  setLoggedIn: (loginState: boolean) => void;
};
export const Login: FC<LoginProps> = ({ setLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailAndPasswordValid, setEmailAndPasswordValid] = useState(false);

  const { isLoading, error, data } = useSignInQuery(email, password, emailAndPasswordValid);

  useEffect(() => {
    if (data) {
      setLoggedIn(true);
    }
  }, [data, setLoggedIn]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailAndPasswordValid(true);
  };

  if (isLoading) {
    return <div>Loading</div>;
  }
  return (
    <PageContainer>
      <CommonPageContent>
        <CommonPageHeader>Login</CommonPageHeader>
        <FormWrapper>
          <div className="form__wrapper">
            <form onSubmit={handleSubmit}>
              <div className="form__input">
                <p>Email</p>
                <input className="clean" name="email" type="text" value={email} onChange={(event) => setEmail(event.target.value)} />
              </div>
              <div className="form__input">
                <p>Password</p>
                <input className="clean" name="password" type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
              </div>
              <input className="enquire__send" type="submit" value="Login" />
            </form>
            {error && <div>Error</div>}
          </div>
        </FormWrapper>
      </CommonPageContent>
    </PageContainer>
  );
};
