import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

type SignInResponse = {
  localId: string;
  email: string;
  displayName: string;
  idToken: string;
  registered: boolean;
  refreshToken: string;
  expiresIn: string;
};

const signInApi = async (email: string, password: string): Promise<SignInResponse> => {
  const response = await fetch('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBc0DQ6EZUS_mz2G71JnPfMa9eqVmXyEWA', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, returnSecureToken: true }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
  Cookies.set('token', data.idToken, { expires: inFifteenMinutes });
  return data;
};

export const useSignInQuery = (email: string, password: string, emailAndPasswordValid: boolean) => {
  const signIn = useQuery({
    queryKey: ['signIn', { email, password }],
    queryFn: () => signInApi(email, password),
    enabled: emailAndPasswordValid,
  });
  return signIn;
};
