import dayjs from 'dayjs';

type TableCell = {
  day: string;
  status: string;
};
const outOfSeasonMonthIndexes = [1, 2, 3, 4, 11, 12];

// [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach((index) => {
export type Week = TableCell[];

export const createCalendarMonth = (monthIndex: number, year: number, bookedDays: number[]): Week[] => {
  //calendars days can span over 6 rows so create a container for 6 rows of 7 cells (42)
  const table42Cells: TableCell[] = Array(42).fill({ day: ' ', status: 'c' });
  const firstDayOfMonth = dayjs(`${year}-${monthIndex}-01`, 'YYYY-M-DD');
  const dayIndex = firstDayOfMonth.day();
  const numberOfDaysInMonth = firstDayOfMonth.daysInMonth();
  const status = outOfSeasonMonthIndexes.includes(monthIndex) ? 'u' : 'a';
  let dayNumber = 1;
  for (let i = dayIndex; i < numberOfDaysInMonth + dayIndex; i++) {
    table42Cells[i] = { day: `${dayNumber}`, status };
    dayNumber = dayNumber + 1;
  }
  bookedDays.forEach((bookedDay) => {
    const cellForDay = table42Cells.find((cell) => cell.day === `${bookedDay}`);
    if (cellForDay) {
      cellForDay.status = 'b';
    }
  });
  const weeks = [];

  let index = 0;
  while (table42Cells.length > 0) {
    const week = table42Cells.splice(0, 7);
    weeks[index] = week;
    index++;
  }
  return weeks;
};

export const isMonthOutOfSeason = (month: string) => {
  return ['january', 'february', 'march', 'april', 'november', 'december'].indexOf(month.toLowerCase()) > -1;
};
