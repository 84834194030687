import React, { FC, useEffect, useState } from 'react';

import { PageContainer, CommonPageContent, CommonButton, FlexRowCentred, CommonPageHeader } from '../common-styles/common-styles';
import * as CSS from './contact-us.styles';
import { useSendEnquiry } from '../../api/enquiry';
import { Spinner } from '../spinner/spinner';

const validEmail = (email: string): boolean => {
  // eslint-disable-next-line
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const ContactUs: FC = () => {
  const [dataValid, setDataValid] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);

  const { isLoading, error, data } = useSendEnquiry(email, name, message, dataValid);

  useEffect(() => {
    if (data && !sendSuccess) {
      setSendSuccess(true);
      setDataValid(false);
      setName('');
      setEmail('');
      setEmailConfirm('');
      setMessage('');
    }
    if (error) {
      setDataValid(false);
    }
  }, [data, error, sendSuccess]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validFormData()) {
      setDataValid(true);
    }
  };

  const validFormData = (): boolean => {
    let returnValue = true;
    if (name) {
      setNameError(false);
    } else {
      setNameError(true);
      returnValue = false;
    }
    if (validEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
      returnValue = false;
    }
    if (email === emailConfirm) {
      setMismatch(false);
    } else {
      setMismatch(true);
      returnValue = false;
    }
    if (message) {
      setMessageError(false);
    } else {
      setMessageError(true);
      returnValue = false;
    }
    return returnValue;
  };

  return (
    <PageContainer>
      <CommonPageContent>
        <CommonPageHeader>Contact us</CommonPageHeader>
        <p>
          If you have any questions or would like to enquire about availability or prices please complete the form below and click 'Send' and we'll get back to you as quick as we
          can.
        </p>
        {sendSuccess ? <CSS.EnquirySuccess>Thanks for your enquiry we'll get back to as soon as possible.</CSS.EnquirySuccess> : null}
        <CSS.FormWrapper>
          {error ? <CSS.ErrorDescription>Oops something went wrong :-(</CSS.ErrorDescription> : null}
          <form onSubmit={(event) => handleSubmit(event)} autoComplete="off">
            <CSS.FormInput>
              <p>Name</p>
              <input type="text" name="name" value={name} onChange={(event) => setName(event?.target.value)} />
              {nameError ? <CSS.ErrorDescription>Please provide a name</CSS.ErrorDescription> : null}
            </CSS.FormInput>
            <CSS.FormInput>
              <p>Email *</p>
              <input name="email" type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
              {emailError ? <CSS.ErrorDescription>email is invalid</CSS.ErrorDescription> : null}
            </CSS.FormInput>
            <CSS.FormInput>
              <p>Confirm Email</p>
              <input name="emailConfirm" type="email" value={emailConfirm} onChange={(event) => setEmailConfirm(event.target.value)} />
              {mismatch ? <CSS.ErrorDescription>Email and confirm email do not match</CSS.ErrorDescription> : null}
            </CSS.FormInput>
            <CSS.FormInput>
              <p>Message (required)</p>
              <div>
                <CSS.EnquireTextarea
                  className="enquire__textarea"
                  name="your-message"
                  value={message}
                  rows={10}
                  aria-required="true"
                  aria-invalid="false"
                  onChange={(event) => setMessage(event.target.value)}
                />
              </div>
              {messageError ? <CSS.ErrorDescription>Please provide a message</CSS.ErrorDescription> : null}
            </CSS.FormInput>
            <FlexRowCentred>{isLoading ? <Spinner size="extraSmall" /> : <CommonButton type="submit">Send</CommonButton>}</FlexRowCentred>
          </form>
          <CSS.EnquireFootNote>* we don't store or share email addresses entered here.</CSS.EnquireFootNote>
        </CSS.FormWrapper>
      </CommonPageContent>
    </PageContainer>
  );
};

export default ContactUs;
