import styled from 'styled-components';
import { devices } from '../common-styles/common-styles';

export const GuestInfo = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  margin: 20px auto;
  color: black;

  @media ${devices.tablet} {
    width: 90%;
  }

  li {
    margin: 5px auto;
    font-size: 15px;
  }

  p {
    font-size: 15px;
  }
`;

export const GuestInfoLi = styled.li`
  margin: 5px auto;
`;

export const PdfWrapper = styled.div`
  padding: 30px;
  border-radius: 5px;
  background-color: white;
  margin: 20px auto;
  color: black;

  @media ${devices.tablet} {
    width: 90%;
  }

  p {
    margin-bottom: 0;
  }
`;
