import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import * as CSS from './prices.styles';
import { CommonPageHeader } from '../common-styles/common-styles';
type Price = {
  month: string;
  price: string;
};
type PricesProps = {
  prices: Price[];
};
const Prices: FC<PricesProps> = ({ prices }) => (
  <div>
    <CommonPageHeader>Prices</CommonPageHeader>

    <CSS.PricesWrapper>
      {prices.map((price, index) => (
        <CSS.PricesMonths key={`price-${index}`}>
          <CSS.PricesMonth>{price.month}</CSS.PricesMonth>
          <CSS.PricesPrice>{price.price}</CSS.PricesPrice>
        </CSS.PricesMonths>
      ))}
    </CSS.PricesWrapper>
    <div>
      <CSS.PricesCopy>Prices are on a per week basis and pro-rated for overlapping weeks. All prices include the Turkish 2% accommodation tax.</CSS.PricesCopy>
      <CSS.PricesCopy>
        If you would like any further information or a quote please <Link to="/contact-us">contact us here.</Link>
      </CSS.PricesCopy>
    </div>
  </div>
);
export default Prices;
