import React, { useState } from 'react';
import CarouselContainer from './carousel-container';
import { galleryItems } from './carouselItems/items';

import * as CSS from './gallery.styles';
import { CommonPageHeader } from '../common-styles/common-styles';

const Gallery = () => {
  const [category, setCategory] = useState('all');
  return (
    <CSS.GalleryContainer>
      <CSS.GalleryWrapper>
        <CommonPageHeader>Gallery</CommonPageHeader>
        <CSS.GalleryCategory>
          <p>A collection of photos.</p>
          <CSS.GalleryCategoryButtons>
            <CSS.GalleryCategoryButton
              selected={category === 'all'}
              onClick={() => {
                setCategory('all');
              }}
            >
              Show all
            </CSS.GalleryCategoryButton>
            <CSS.GalleryCategoryButton
              selected={category === 'kitchen'}
              onClick={() => {
                setCategory('kitchen');
              }}
            >
              kitchen
            </CSS.GalleryCategoryButton>
            <CSS.GalleryCategoryButton
              selected={category === 'outside'}
              onClick={() => {
                setCategory('outside');
              }}
            >
              outside
            </CSS.GalleryCategoryButton>
            <CSS.GalleryCategoryButton
              selected={category === 'bedrooms'}
              onClick={() => {
                setCategory('bedrooms');
              }}
            >
              bedrooms
            </CSS.GalleryCategoryButton>
          </CSS.GalleryCategoryButtons>
        </CSS.GalleryCategory>
        {/* <FlexColumnCentred>{getItems()}</FlexColumnCentred> */}
        <CSS.CarouselWrapperMobile>
          <CarouselContainer carouselItems={galleryItems.gallery} size="mobile" controls={true} showCaption={false} category={category} />
        </CSS.CarouselWrapperMobile>
        <CSS.CarouselWrapperTablet>
          <CarouselContainer carouselItems={galleryItems.gallery} size="tablet" controls={true} showCaption={true} category={category} />
        </CSS.CarouselWrapperTablet>
        <CSS.CarouselWrapperWide>
          <CarouselContainer carouselItems={galleryItems.gallery} size="wide" controls={true} showCaption={true} category={category} />
        </CSS.CarouselWrapperWide>
      </CSS.GalleryWrapper>
    </CSS.GalleryContainer>
  );
};

export default Gallery;
