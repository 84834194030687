import styled from 'styled-components';

type LoaderProps = {
  className?: string;
  'data-testid'?: string;
};

const Loader = styled.div<LoaderProps>`
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

type CircularProps = {
  className?: string;
  viewBox?: string;
};

const Circular = styled.svg<CircularProps>`
  animation: rotate 7.6s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

type PathProps = {
  className?: string;
  cx?: string;
  cy?: string;
  r?: string;
  stroke?: string;
  fill?: string;
  strokeWidth?: string;
  strokeMiterlimit?: string;
  strokeLinecap?: string;
};

const Path = styled.circle<PathProps>`
  animation: dash 3.8s linear infinite;

  @keyframes dash {
    0%,
    2% {
      stroke-dasharray: 126;
      stroke-dashoffset: 252;
    }
    25% {
      stroke-dasharray: 126;
      stroke-dashoffset: 152;
    }
    48%,
    52% {
      stroke-dasharray: 126;
      stroke-dashoffset: 126;
    }
    75% {
      stroke-dasharray: 126;
      stroke-dashoffset: 26;
    }
    98%,
    100% {
      stroke-dasharray: 126;
      stroke-dashoffset: 0;
    }
  }
`;

export { Loader, Circular, Path };
