import React, { FC } from 'react';

import { GuestInfoContainer } from '../guest-info-container';

export const Apartment: FC = () => {
  return (
    <GuestInfoContainer>
      <h3>General Apartment Information</h3>
      <h4>Security</h4>
      <p>There is a safe on the bottom shelf in the wardrobe of the master bedroom. Instructions for use are located by the safe.</p>
      <p>
        Please ensure all windows and doors are closed and locked each time you leave the property. Although Kalkan is a very safe place, opportunist thefts can happen if windows
        or doors are left open.
      </p>
      <h4>Internet Connection</h4>
      <p>WIFI is available. You should have been sent the password already by now, if not you will find the details on a fridge magnet in the apartment.</p>
      <h4>General Housekeeping</h4>
      <p>
        In an attempt to keep the outside cushions clean for all our guests to enjoy, we would ask that you use the pool towels provided at all times! Thank you for your
        cooperation.
      </p>
      <h4>Smoking</h4>
      <p>For the safety and comfort of all our guests, we would respectfully request that you do not smoke inside the apartment.</p>
      <h4>Power</h4>
      <p>To conserve energy we kindly ask that you switch off all lights and air-conditioning each time you leave the apartment.</p>
      <h4>Drinking Water</h4>
      <p>As with most foreign countries, please avoid drinking tap water.</p>
    </GuestInfoContainer>
  );
};
