import styled from 'styled-components';

import { devices } from '../common-styles/common-styles';

export const PricesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 300px;

  @media ${devices.tablet} {
    flex-wrap: nowrap;
  }
`;

export const PricesBlock = styled.div`
  display: block;
  margin-right: -2px;
  margin-bottom: 20px;

  @include tablet {
    display: inline-block;
    border: 1px solid grey;
  }
`;

export const PricesMonths = styled.div`
  min-width: 90px;
  display: inline-block;
  margin: 0 0 15px;

  div {
    border: 1px solid darkgray;
    padding: 7px;
  }
  @media ${devices.tablet} {
    min-width: 115px;
  }
`;

export const PricesPrice = styled.div`
  /* width: 100px; */
  background-color: white;
  color: black;
`;

export const PricesMonth = styled.div`
  color: white;
  background-color: #6c8bb9;
`;

export const PricesCopy = styled.p`
  margin-bottom: 20px;
  a {
    color: white;
  }
`;
