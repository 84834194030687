import React, { FC } from 'react';

import { PageContainer, CommonPageContent, CommonPageHeader } from '../common-styles/common-styles';
import * as CSS from './guest-info-styles';
import Navigation from './navigation/navigation';

type GuestInfoProps = {
  children: React.ReactNode;
};
export const GuestInfoContainer: FC<GuestInfoProps> = ({ children }) => {
  return (
    <PageContainer>
      <CommonPageContent data-testid="common-page-content">
        <Navigation />
        <CSS.GuestInfo>{children}</CSS.GuestInfo>
      </CommonPageContent>
    </PageContainer>
  );
};
