import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as CSS from './navigation-styles';
import { FlexRowCentred } from '../../common-styles/common-styles';

const Navigation = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <CSS.NavigationWrapper data-testid="nav wrapper">
      <CSS.MenuFred data-testid="hamburger--fred" style={showNav ? { right: '0', background: 'rgba(1, 81, 145, 1)' } : { right: '-16rem', background: 'transparent', zIndex: 1 }}>
        <CSS.CheckNav id="nav-menu-state" onClick={() => setShowNav(!showNav)} />
        <CSS.HamburgerContainer data-testid="hamburger-container" onClick={() => setShowNav(!showNav)}>
          <FlexRowCentred style={{ position: 'inherit' }} data-testid="wrong">
            <CSS.HamburgerPrefixText>Content</CSS.HamburgerPrefixText>
            <div>
              <CSS.HamburgerBar1 data-testid="bar1" style={showNav ? { transform: 'translate(2px, 8px) rotate(-45deg)' } : { transform: 'none' }}></CSS.HamburgerBar1>
              <CSS.HamburgerBar2 data-testid="bar2" style={showNav ? { opacity: 0 } : { opacity: 1 }}></CSS.HamburgerBar2>
              <CSS.HamburgerBar1 data-testid="bar3" style={showNav ? { transform: 'translate(2px, -11px) rotate(45deg)' } : { transform: 'none' }}></CSS.HamburgerBar1>
            </div>
          </FlexRowCentred>
        </CSS.HamburgerContainer>
        <CSS.MenuUl data-testid="menu-ul" style={showNav ? { opacity: 1 } : { opacity: 0 }}>
          <li data-testid="location" onClick={() => setShowNav(!showNav)}>
            <Link to="/guest-info">Welcome</Link>
          </li>
          <li data-testid="contacts" onClick={() => setShowNav(!showNav)}>
            <Link to="/guest-info/contacts">Contacts</Link>
          </li>
          <li data-testid="apartment" onClick={() => setShowNav(!showNav)}>
            <Link to="/guest-info/apartment">Apartment</Link>
          </li>
          <li data-testid="restaurants" onClick={() => setShowNav(!showNav)}>
            <Link to="/guest-info/restaurants">Restaurants</Link>
          </li>
          <li data-testid="things-to-do" onClick={() => setShowNav(!showNav)}>
            <Link to="/guest-info/things-to-do">Things To Do</Link>
          </li>
          <li data-testid="shopping" onClick={() => setShowNav(!showNav)}>
            <Link to="/guest-info/shopping">Shopping</Link>
          </li>
        </CSS.MenuUl>
      </CSS.MenuFred>
      <CSS.MenuHeaderWrapper>
        <CSS.MenuHeader>Guest Information</CSS.MenuHeader>
      </CSS.MenuHeaderWrapper>
    </CSS.NavigationWrapper>
  );
};

export default Navigation;
