import { useQuery, useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

export type Price = {
  month: string;
  price: string;
};
export type Prices = Record<string, Price[]>;
export type Month = Record<string, string[]>;
export type MonthBookedAvailability = Record<string, number[]>;
type YearBookedAvailability = Record<string, MonthBooking[]>;

type MonthBooking = {
  monthIndex: number;
  bookings: number[];
};
export type Availability = {
  bookings: YearBookedAvailability;
  prices: Price[];
};

const fetchAvailability = async (): Promise<Availability> => {
  const response = await fetch('https://fabio-17177.firebaseio.com/availability.json');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

type UpdateData = {
  data: YearBookedAvailability;
  year: number;
};

const mutateAvailability = async (updateData: UpdateData): Promise<Availability> => {
  const authToken = Cookies.get('token');
  const response = await fetch(`https://fabio-17177.firebaseio.com/availability/bookings/${updateData.year}.json?auth=${authToken}`, {
    method: 'PUT', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateData.data),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const useAvailabilityQuery = () =>
  useQuery({
    queryKey: ['availability'],
    queryFn: fetchAvailability,
  });

export const useAvailabilityMutation = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutateAvailability,
    onSuccess: () => {
      // Invalidate and refetch
      // queryClient.invalidateQueries({ queryKey: ['availability'] });
    },
  });
};
