import React, { FC } from 'react';

import { GuestInfoContainer } from '../guest-info-container';

export const Restaurants: FC = () => {
  return (
    <GuestInfoContainer>
      <h3>Restaurants</h3>
      <p>You are going to be spoilt for choice - this is what Kalkan is all about!</p>
      <p>There are well over 100 restaurants in Kalkan, in addition to the café's. Everyone has a favourite, so it's good to try a few for yourselves or go by recommendations.</p>
      <p>Some of our favourite restaurants are (in no particular order):</p>
      <ul>
        <li>Chameleon</li>
        <li>The View</li>
        <li>Sade</li>
        <li>Baharat</li>
        <li>Seaport</li>
        <li>Aubergine</li>
        <li>Trio</li>
      </ul>
      <p>
        The list goes on and on. For a nice easy lunch in town are Merkez café, Seaport, Marina Restaurant. Closer to the apartment are Saray Suites and Lime Bar, plus many more.
      </p>
      <h3>Take-away/Deliveries</h3>
      <p>There is a great app specifically for Kalkan restaurants, it is called 'Villa Eats'.</p>
      <p>The app is easy to use but you will need to set up your loction first.</p>
      <p>You can search for different foods etc and additionally, you will find supermarkets on there as well.</p>
      <p> It is well worth using this app.</p>
      **PLEASE NOTE THAT YOU WILL NEED TO PAY CASH (LIRA) ON DELIVERY**
    </GuestInfoContainer>
  );
};
