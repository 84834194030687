import React, { FC } from 'react';

import { GuestInfoContainer } from '../guest-info-container';

export const Contacts: FC = () => {
  return (
    <GuestInfoContainer>
      <h3>Contact Information</h3>
      <h4>Maintenance Team</h4>
      <p>Any problems with the apartment please contact:</p>
      <p>David Smyth: 0090 533 420 2989</p>
      <h4>Emergency Services</h4>
      <p>
        <strong>Police: 155</strong>
      </p>
      <p>
        <strong>Ambulance: 112</strong>
      </p>
      <p>
        <strong>Fire: 115</strong>
      </p>
    </GuestInfoContainer>
  );
};
