import styled from 'styled-components';
import { devices } from '../common-styles/common-styles';

export const GalleryContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #111010;
  z-index: -2;
`;

export const GalleryWrapper = styled.div`
  border-radius: 5px;
  margin-top: 2rem;
  color: white;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media ${devices.tablet} {
    margin-top: 4rem;
  }
  @media ${devices.laptop} {
    margin-top: 6rem;
    border: 1px solid white;
    text-align: left;
    width: 70%;
  }
  @media ${devices.desktop} {
    width: 70%;
  }
`;

export const GalleryCategory = styled.div`
  position: relative;
  z-index: 10;
  background-color: dimgray;
  padding: 10px 0 10px 5px;
  text-align: left;
  @media ${devices.tablet} {
    padding: 10px 0 10px 45px;
  }
  @media ${devices.laptop} {
    padding: 10px 20px;
  }
`;

export const GalleryCategoryButtons = styled.div`
  padding-bottom: 10px;
  margin-left: 38px;
`;

export const GalleryCategoryButton = styled.button<{ selected?: boolean | false }>`
  border: ${(props) => (props.selected ? '1px solid white' : 'none')};
  background-color: ${(props) => (props.selected ? 'black' : 'lightgray')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  padding: 8px;
  margin-right: 6px;

  @media ${devices.tablet} {
    padding: 9px;
    margin-right: 10px;
    margin-right: 20px;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselWrapperMobile = styled(CarouselWrapper)`
  @media ${devices.tablet} {
    display: none;
  }
`;

export const CarouselWrapperTablet = styled(CarouselWrapper)`
  display: none;

  @media ${devices.tablet} {
    display: block;
  }
  @media ${devices.desktop} {
    display: none;
  }
`;
export const CarouselWrapperWide = styled(CarouselWrapper)`
  display: none;

  @media ${devices.desktop} {
    display: block;
  }
`;

export const CarouselContainer = styled.div`
  //position: relative;
  //top: -100px;
  //left: 0;
  /* height: 250px; */
  width: 100%;
  overflow: hidden;
  /* @media ${devices.tablet} {
    height: 500px;
  }
  @media ${devices.desktop} {
    height: 650px;
  }
  @media ${devices.desktop} {
    height: 1000px;
  } */
  //height: 100vh;
`;

export const containerFluid = styled.div`
  .slick-slider {
    padding-left: -15px;
  }
`;

export const CarouselCaption = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  position: absolute; /* Position the background text */
  top: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.25); /* Black background with 0.5 opacity */
  color: #f1f1f1; /* Grey text */
  padding: 8px 20px; /* Some padding */
  width: 100%;

  p {
    font-size: 14px;
    margin-top: 10px;
  }

  h1 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 12px;
  }

  @media ${devices.laptop} {
    background: rgba(0, 0, 0, 0.25); /* Black background with 0.5 opacity */
    font-size: 20px;
    padding: 20px 30px;

    p {
      font-size: 20px;
      margin-top: 20px;
    }

    h1 {
      font-size: 30px;
    }
  }
  @media ${devices.desktop} {
    padding: 30px;
  }
`;

export const CarouselCaptionDescription = styled.div`
  font-size: 18px;
  margin-top: 15px;

  @media ${devices.desktop} {
    font-size: 22px;
    margin-top: 30px;
  }
`;

export const CarouselItem = styled.div`
  position: relative;
  img {
    display: block;
    width: 100%;
    padding: 4px 8px;
    background-color: dimgray;
    @media ${devices.tablet} {
      padding: 7px 14px;
    }
  }
`;
