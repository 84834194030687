import { YearBookedAvailability } from '../../components/availability/availability';

export const updateTheAvailability = (bookedAvailability: YearBookedAvailability, day: number, month: number, year: number): YearBookedAvailability => {
  const cloneBookedAvailability = JSON.parse(JSON.stringify(bookedAvailability)) as YearBookedAvailability;
  const yearBooked = cloneBookedAvailability[year];
  if (yearBooked) {
    const monthBookings = yearBooked.find((monthBooking) => monthBooking.monthIndex === month);
    if (monthBookings) {
      if (monthBookings.bookings.includes(day)) {
        const index = monthBookings.bookings.findIndex((booking) => booking === day);

        monthBookings.bookings.splice(index, 1);
        if (monthBookings.bookings.length === 0) {
          const index = yearBooked.findIndex((booking) => booking.monthIndex === month);
          yearBooked.splice(index, 1);
        }
      } else {
        monthBookings.bookings.push(day);
        monthBookings.bookings.sort((a, b) => a - b);
      }
    } else {
      yearBooked.push({ monthIndex: month, bookings: [day] });
    }
  } else {
    cloneBookedAvailability[year] = [{ monthIndex: month, bookings: [day] }];
  }
  return cloneBookedAvailability;
};
