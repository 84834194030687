import styled from 'styled-components';
import { devices } from '../common-styles/common-styles';

export const FormWrapper = styled.div`
  padding: 30px;
  border-radius: 5px;
  background-color: white;
  margin: 30px auto;
  color: black;

  @media ${devices.tablet} {
    width: 100%;
    width: 600px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const FormInput = styled.div`
  color: black;
  margin-bottom: 20px;
  input {
    width: 80%;
    height: 25px;
    font-size: 18px;
  }
`;

export const Error = styled.div`
  border: 2px solid red;
  border-radius: 2px;
`;

export const ErrorDescription = styled.div`
  color: red;
  border: none;
`;

export const EnquireTextarea = styled.textarea`
  width: 100%;
  font-size: 18px;
`;

export const EnquireSend = styled.button`
  background-color: cornflowerblue;
  width: 100px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
`;

export const EnquirySuccess = styled.div`
  color: green;
  font-size: 20px;
  padding: 15px 0;
`;

export const EnquireFootNote = styled.div`
  margin-top: 20px;
  color: gray;
  font-size: 16px;
  padding: 15px 0;
`;
