import styled from 'styled-components';

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};
export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
export const FlexRowCentred = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const FlexRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const FlexRowSpaceAround = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

export const FlexRowSpaceEvenly = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
`;

export const FlexColumnCentred = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0.25rem;
  align-items: center;
`;

export const FlexColumnCentredSpaceAround = styled(FlexColumnCentred)`
  justify-content: space-around;
  height: 100%;
`;

export const FlexColumnCentredSpaceBetween = styled(FlexColumnCentred)`
  justify-content: space-between;
  height: 100%;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #d7d1d1;
  padding: 0.5rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

export const CommonPageContent = styled.div`
  border: 1px solid;
  font-size: 15px;
  position: relative; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.3); /* Black background with 0.5 opacity */
  color: #f1f1f1; /* Grey text */
  padding: 1rem; /* Some padding */
  margin-top: 2rem;
  overflow: hiiden;

  p {
    font-size: 14px;
  }

  h1 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 12px;
  }

  @media ${devices.tablet} {
    background: rgba(0, 0, 0, 0.4); /* Black background with 0.5 opacity */
    margin: 10px;
    font-size: 16px;
    padding: 20px;

    p {
      font-size: 20px;
      margin-top: 20px;
    }

    h1 {
      font-size: 30px;
    }
  }
  @media ${devices.laptop} {
    border-radius: 5px;
  }
`;

export const CommonPageHeader = styled.h1`
  text-align: center;

  @media ${devices.laptop} {
    text-align: left;
  }
`;

export const PageContainer = styled.div`
  position: relative;
  z-index: 22;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  margin-top: 0;
  height: 100%;
  min-height: 90vh;
  overflow: hidden;

  @media ${devices.desktop} {
    margin-top: 6rem;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const CommonButton = styled.button`
  min-width: 200px;
  width: 20rem;
  color: white;
  background-color: #2dbe2d;
  border: none;
  border-radius: 3px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    opacity: 0.7;
    cursor: no-drop;
  }
`;
