import styled from 'styled-components';
import { devices } from '../common-styles/common-styles';

export const HomeContent = styled.div`
  border: 1px solid white;
  border-radius: 3px;
  background: rgba(1, 81, 145, 0.7);
  margin: 40px 10px 0;
  padding: 15px;
  color: white;
  font-size: 16px;
  @media ${devices.tablet} {
    font-size: 18px;
  }
  @media ${devices.laptop} {
    font-size: 20px;
  }
`;

export const HomeFooter = styled.div`
  position: fixed;
  bottom: 1rem;
  color: white;
  padding: 1rem;
  width: 22rem;

  a {
    text-decoration: none;
  }

  @media ${devices.tablet} {
    width: 45rem;
  }
  @media ${devices.laptop} {
    width: 62rem;
  }
  @media ${devices.desktop} {
    width: 80rem;
  }
`;
