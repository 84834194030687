import styled, { keyframes, CSSObject, DefaultTheme } from 'styled-components';

// import { SpinnerSizes } from '../../../../contracts/themes/components/spinner.types';

export type SpinnerProps = {
  size: SpinnerSizes;
  inline?: boolean;
  withOverlay?: boolean;
};

type WidthHeight = { width: string; height: string };

// const spinnerTheme = (theme: DefaultTheme): SpinnerTheme => theme.components.spinner;

const getWidthHeight = (size: number): WidthHeight => ({ width: `${size}px`, height: `${size}px` });
export type SpinnerSizes = 'input' | 'extraSmall' | 'small' | 'medium' | 'large';

const getSize = (size: SpinnerSizes): WidthHeight => {
  const sizes = {
    input: 20,
    extraSmall: 60,
    small: 82,
    medium: 150,
    large: 300,
    base: 150,
  };
  const themeSizes = sizes[size];

  return getWidthHeight(themeSizes);
};

const getOuterWrapperStyles = (theme: DefaultTheme, inline: boolean | undefined, withOverlay: boolean | undefined): CSSObject => {
  const defaultStyles: CSSObject = {
    position: 'relative',
  };

  const baseOverlayStyles: CSSObject = {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  };

  const withOverlayStyles: CSSObject = {
    ...baseOverlayStyles,
    position: 'absolute',
    background: 'white',
  };

  const fullScreenWithOverlayStyles: CSSObject = {
    ...baseOverlayStyles,
    background: 'white',
  };

  if (withOverlay && inline === false) {
    return fullScreenWithOverlayStyles;
  }

  if (withOverlay) {
    return withOverlayStyles;
  }

  if (inline === false) {
    return baseOverlayStyles;
  }

  return defaultStyles;
};

type SpinnerWrapperStyleProps = SpinnerProps;

export const SpinnerWrapper = styled.div<SpinnerWrapperStyleProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  ${({ size }): WidthHeight => getSize(size)}
`;

type OuterWrapperStyleProps = {
  inline?: boolean;
  withOverlay?: boolean;
};

export const OuterWrapper = styled.div<OuterWrapperStyleProps>`
  ${(props): CSSObject => getOuterWrapperStyles(props.theme, props.inline, props.withOverlay)}}
`;

const rotation = keyframes`
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

export const SpinnerInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  animation: ${rotation} linear 2s infinite;
`;

export const SpinnerRing = styled(SpinnerInnerWrapper)`
  animation: ${rotation} linear 1.5s infinite;
  display: block;
  color: #0073c5;
  border: 0.4rem solid;
  border-color: 'transparent #0073C5 #0000C5';
  overflow: hidden;
  text-indent: 100%;
  border-radius: 100%;
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease, visibility 1s ease;
`;

// const SpinnerCircle = styled.div`
//   height: 30%;
//   width: 30%;
//   position: absolute;
//   border-radius: 100%;
//   animation: ${spinnerPulseOdd} ease-in-out 1.5s infinite;
// `;

// export const SpinnerCircleFirst = styled(SpinnerCircle)`
//   top: 22%;
//   left: 22%;
//   background-color: ${({
//     theme: {
//       colors: { colors },
//     },
//   }): string => colors.purple};
// `;
// export const SpinnerCircleSecond = styled(SpinnerCircle)`
//   top: 22%;
//   left: 48%;
//   background-color: ${({
//     theme: {
//       colors: { colors },
//     },
//   }): string => colors.interfaceOrange};
//   animation: ${spinnerPulseEven} ease-in-out 1.5s infinite;
// `;
// export const SpinnerCircleThird = styled(SpinnerCircle)`
//   top: 48%;
//   left: 48%;
//   background-color: ${({
//     theme: {
//       colors: { colors },
//     },
//   }): string => colors.teal};
// `;
// export const SpinnerCircleFourth = styled(SpinnerCircle)`
//   top: 48%;
//   left: 22%;
//   background-color: ${({
//     theme: {
//       colors: { colors },
//     },
//   }): string => colors.oceanBlue};
//   animation: ${spinnerPulseEven} ease-in-out 1.5s infinite;
// `;
