import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Outlet } from 'react-router-dom';
import Navigation from './components/navigation/navigation';
import { devices } from './components/common-styles/common-styles';

const GlobalStyles = createGlobalStyle`
  /* h1, h2, h3, h4, h5, h6 {
    margin: 0;
  } */

  box-sizing: border-box;
  html {
    min-height: 100%;
  }
  body {
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
    background-image: url('/assets/evening1_tablet.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    @media ${devices.laptop} {
      background-image: url('/assets/evening1_wide.jpg');
    }
  }
`;

function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <Navigation />
      <Outlet />
    </div>
  );
}

export default App;
