import React, { FC } from 'react';
import * as CSS from './availability.styles';

export const getColor = (availability: string): string => {
  switch (availability) {
    case 'a':
      return '#00bb00d1';
    case 'b':
      return '#d10000d1';
    case 'u':
      return '#ef651ed1';
    default:
      return '#b9b9b9';
  }
};

type DayProps = {
  availabilty: string;
  day: string;
  year: number;
  month: number;
  setAvailability?: (day: number, month: number, year: number) => void;
};
const Day: FC<DayProps> = ({ availabilty, day, year, month, setAvailability }) => {
  return (
    <CSS.Day bgcolor={getColor(availabilty)} onClick={() => (setAvailability ? setAvailability(Number(day), month, year) : () => {})}>
      {day}
    </CSS.Day>
  );
};

export default Day;
