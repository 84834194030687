import React, { useState } from 'react';
import { Login } from '../login/login';
import { Availability } from '../availability/availability';
import { isSignedIn } from '../../utils/auth';

export const Admin = () => {
  const [loggedIn, setLoggedIn] = useState(isSignedIn);

  if (!loggedIn) {
    return <Login setLoggedIn={setLoggedIn} />;
  }

  return <Availability isUpdating={true} />;
};
