import React, { FC } from 'react';

import { GuestInfoContainer } from '../guest-info-container';

export const Welcome: FC = () => {
  return (
    <GuestInfoContainer>
      <h3>Welcome to Fabio at the Ekin Apartments!</h3>
      <p>
        We really hope you enjoy your stay. Fabio is perfectly situated on the beautiful Ekin complex, located close to the Old Town and Harbour. We hope you find everything you
        need to have a relaxing and enjoyable stay.
      </p>
      <p>
        Fabio is run by a professional management team who will keep the apartment and pool in tiptop condition. If you find anything broken or not working, please inform David who
        will endeavour to rectify any issues. Please see business card inside apartment.
      </p>
      <p>
        The apartment will be cleaned and ready for your arrival. For those bookings for more than 10 nights, there will also be a mid-stay clean which will include a change of
        sheets and bath towels.
      </p>
      <p>A washing machine is provided on the lower floor should you want to freshen up your pool towels during your stay.</p>
      <p>For your convenience, the nearest bin is located just on the road near the end of the swimming pool. It is for public use.</p>
      <p>Please try to empty your rubbish regularly otherwise you may attract ants.</p>
    </GuestInfoContainer>
  );
};
