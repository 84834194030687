import React, { FC } from 'react';

// import { SpinnerSizes, SpinnerType } from '../../../../contracts/themes/components/spinner.types';

// import RingSpinner from './ringSpinner';
import * as CSS from './spinner.styles';
import { Loader, Circular, Path } from './ouroborosSpinner.styles';

export type SpinnerSizes = 'input' | 'extraSmall' | 'small' | 'medium' | 'large';
export type SpinnerProps = {
  size?: SpinnerSizes;
  inline?: boolean;
  withOverlay?: boolean;
};

export const Spinner: FC<SpinnerProps> = ({ size = 'medium', inline, withOverlay }) => (
  <CSS.OuterWrapper data-testid="spinner-outer" inline={inline} withOverlay={withOverlay}>
    <CSS.SpinnerWrapper data-testid="spinner" size={size}>
      <Loader className="loader" data-testid="spinner-wrapper">
        <Circular className="circular" viewBox="25 25 50 50">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#fff" />
              <stop offset="20%" stopColor="#cc0a1a" />
              <stop offset="100%" stopColor="#c9676f" />
            </linearGradient>
          </defs>
          <Path className="path" cx="50" cy="50" r="20" stroke="url('#gradient')" fill="none" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="square" />
        </Circular>
      </Loader>
    </CSS.SpinnerWrapper>
  </CSS.OuterWrapper>
);
