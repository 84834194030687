import styled from 'styled-components';
import { devices } from '../common-styles/common-styles';

export const NavigationWrapper = styled.div`
  a {
    color: white;
  }

  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }
`;

export const MenuHeaderWrapper = styled.div`
  color: white;
  padding: 0 1rem;
  text-align: right;
  margin-top: 1.5rem;

  @media ${devices.tablet} {
    margin-top: 1.5rem;
    padding: 0 1rem;
  }
  @media ${devices.laptop} {
    margin-top: 2rem;
  }
`;

export const MenuHeader = styled.h1`
  font-size: 1.5rem;
  font-weight: 100;
  margin: 0;
  @media ${devices.tablet} {
    font-size: 1.75rem;
    text-align: center;
    font-weight: normal;
  }
  @media ${devices.laptop} {
    font-size: 2rem;
  }
`;

export const MenuFred = styled.div`
  top: 0;
  left: -18rem;
  position: absolute;
  width: 20rem;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  transition: left 2s, background-color 2s;
  z-index: 999;
  @media ${devices.tablet} {
    width: 23rem;
  }
  @media ${devices.laptop} {
    width: 25rem;
  }
`;

export const MenuHolder = styled.div`
  position: relative;
  top: 1rem;
  left: -8rem;
  padding-top: 1rem;
  color: #f1f1f1; /* Grey text */
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.8); /* Black background with 0.5 opacity */
  display: block;
  flex-direction: column;
  width: 10rem;
  z-index: 999999;
  height: 100%;
  transition: left 2s, opacity 2s;
`;

export const HamburgerBar = styled.div`
  width: 35px;
  height: 2px;
  background-color: white;
  margin: 8px 0;
  transition: 0.4s;
`;

export const HamburgerBar1 = styled(HamburgerBar)``;
export const HamburgerBar2 = styled(HamburgerBar)``;
export const HamburgerBar3 = styled(HamburgerBar)``;

export const MenuUl = styled.ul`
  padding: 4rem 1rem;
  li {
    margin-bottom: 50px;
    display: block;
  }
`;

export const CheckNav = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const HamburgerPrefixText = styled.div`
  padding-right: 0.5rem;
  color: white;
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

export const HamburgerContainer = styled.div`
  display: block;
  cursor: pointer;
  z-index: 999999;
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 0;
  @media ${devices.tablet} {
    right: 2rem;
  }
  @media ${devices.tablet} {
    right: 0rem;
    padding: 1rem 0.5rem;
  }
  @media ${devices.desktop} {
    right: 0rem;
  }
`;

export const Hamburger = styled.label`
  position: fixed;
  left: -290px;
  top: 10px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: end;
  width: 90%;
  padding-right: 20px;
  z-index: 300;
  svg rect {
    color: white;
    transition: all 0.5s;
  }
  ${CheckNav}:checked
  + svg rect:nth-child(1) {
    transform-origin: 0 10px;
    transform: rotate(32deg);
  }

  ${CheckNav}:checked
  + svg rect:nth-child(2) {
    opacity: 0;
  }

  ${CheckNav}:checked
  + svg rect:nth-child(3) {
    transform-origin: 20px 20px;
    transform: rotate(-32deg);
  }
  transition: left 2s;
  @media ${devices.laptop} {
    display: none;
  }
`;
