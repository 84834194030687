import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as CSS from './navigation-styles';
import { FlexRowCentred } from '../common-styles/common-styles';

const Navigation = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <CSS.NavigationWrapper data-testid="nav wrapper">
      <CSS.MenuFred data-testid="hamburger--fred" style={showNav ? { left: '0', background: 'rgba(1, 81, 145, 1)' } : { left: '-16rem', background: 'transparent', zIndex: 1 }}>
        <CSS.CheckNav id="nav-menu-state" onClick={() => setShowNav(!showNav)} />
        <CSS.HamburgerContainer onClick={() => setShowNav(!showNav)}>
          <FlexRowCentred>
            <CSS.HamburgerPrefixText>MENU</CSS.HamburgerPrefixText>
            <div>
              <CSS.HamburgerBar1 data-testid="bar1" style={showNav ? { transform: 'translate(2px, 8px) rotate(-45deg)' } : { transform: 'none' }}></CSS.HamburgerBar1>
              <CSS.HamburgerBar2 data-testid="bar2" style={showNav ? { opacity: 0 } : { opacity: 1 }}></CSS.HamburgerBar2>
              <CSS.HamburgerBar1 data-testid="bar3" style={showNav ? { transform: 'translate(2px, -11px) rotate(45deg)' } : { transform: 'none' }}></CSS.HamburgerBar1>
            </div>
          </FlexRowCentred>
        </CSS.HamburgerContainer>
        <CSS.MenuUl data-testid="menu-ul">
          <li data-testid="location" onClick={() => setShowNav(!showNav)}>
            <Link to="/">HOME</Link>
          </li>
          <li data-testid="location" onClick={() => setShowNav(!showNav)}>
            <Link to="/location">AMENETIES & LOCATION</Link>
          </li>
          <li>
            <Link to="/availability" onClick={() => setShowNav(!showNav)}>
              PRICES &amp; AVAILABILITY
            </Link>
          </li>
          <li>
            <Link to="/gallery" onClick={() => setShowNav(!showNav)}>
              GALLERY
            </Link>
          </li>
          <li>
            <Link to="/contact-us" onClick={() => setShowNav(!showNav)}>
              CONTACT US
            </Link>
          </li>
        </CSS.MenuUl>
      </CSS.MenuFred>
      <CSS.MenuHeaderWrapper>
        <CSS.MenuHeader>Apartment Fabio</CSS.MenuHeader>
      </CSS.MenuHeaderWrapper>
    </CSS.NavigationWrapper>
  );
};

export default Navigation;
