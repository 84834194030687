import React, { FC } from 'react';
import { PageContainer, CommonPageContent, CommonPageHeader } from './common-styles/common-styles';
import styled from 'styled-components';

const IframeWrapper = styled.iframe`
  border: 1px gray solid;
  width: 100%;
  height: 450px;
`;

const Location: FC = () => (
  <PageContainer>
    <CommonPageContent>
      <CommonPageHeader>Ameneties</CommonPageHeader>
      <p>An exceptionally spacious, contemporary, fully air conditioned 3 bedroom, 3 bathroom duplex apartment, comfortably sleeping 6 adults.</p>
      <p> Two of the bedrooms have en suite bathrooms and there is a large main bathroom.</p>
      <p>Each bedroom opens on to a downstairs courtyard</p>
      <p>A large private swimming pool and terrace.</p>

      <p>The light, airy and spacious lounge with dining area and fully equipped kitchen is on the ground floor.</p>
      <p> Large glass doors lead out of the lounge onto the large private swimming pool and terrace.</p>
    </CommonPageContent>
    <CommonPageContent>
      <CommonPageHeader>Location</CommonPageHeader>
      <div>
        <p>Kalkan is a small peaceful Mediterranean resort and fishing town on the beautiful Turquoise Coast of Turkey, which has not been touched by mass tourism.</p>
        <p>Apartment Fabio is a 8-10 minute walk to the old town.</p>
        <IframeWrapper
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3216.88361766334!2d29.40643757804349!3d36.266604372404394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c02cd7d59b1a19%3A0xcb726719f7d3b6c!2sApartment%20Fabio%2C%20Ekin%20Apartments%2C%20Kalkan!5e0!3m2!1sen!2suk!4v1723475783050!5m2!1sen!2suk"
          width="600"
          height="450"
          allowFullScreen
          loading="lazy"
        ></IframeWrapper>
        <p>Nearby are shops and little supermarkets which cater for your every need.</p>

        <p>It's a 10 minute walk to Kalkan's busy market which is held on a Thursday and well worth the experience.</p>

        <p>
          You can enjoy a swim in the turquoise sea either from Kalkan's little beach or from one of the many beach clubs which have water sports and swimming platforms, all within
          easy reach.
        </p>

        <p>All along the harbour are boats waiting to take you on lazy days at sea - not to be missed.</p>
      </div>
      <p>The nearest airport is in Dalaman, which is served by most airlines from all across the UK, and is approximately 90 minutes away by car.</p>
      <p>More sophisticated than the usual resort town, Kalkan appeals to travelers looking for more than a "sun and sea" holiday.</p>
      <p>According to the Sunday Times, Kalkan attracts the kind of visitor who would also enjoy Tuscany or the Dordogne.</p>
      <p>The Guardian likens the town to "the Italian Riviera minus the poseurs."</p>
    </CommonPageContent>
  </PageContainer>
);

export default Location;
