import React, { FC, useEffect, useState } from 'react';
import CalendarMonth from './calendar-month';
import Prices from '../prices/prices';
import * as CSS from './availability.styles';
import { PageContainer, CommonPageContent, CommonPageHeader } from '../common-styles/common-styles';
import { getColor } from './day';
import { CalendarCarousel } from './carousel';
import { useAvailabilityQuery, Price } from '../../api/availability';
import { Spinner } from '../spinner/spinner';
import { updateTheAvailability } from '../../utils/bookings/update-bookings';
import { UpdateButton } from './update-button';

export type MonthBookedAvailability = Record<string, number[]>;
// type YearBookedAvailability = Record<string, MonthBookedAvailability>;
export type YearBookedAvailability = Record<string, MonthBooking[]>;
export type UpdateStatus = {
  success: boolean;
  error: boolean;
};
type MonthBooking = {
  monthIndex: number;
  bookings: number[];
};
const monthsOrder = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const getBookedAvailability = (month: number, year: number, availability: YearBookedAvailability): number[] => {
  const yearAvailability = availability[`${year}`];
  if (yearAvailability) {
    return yearAvailability.find((yearMonth) => yearMonth.monthIndex === month)?.bookings ?? [];
  }
  return [];
};

type AvailabilityProps = {
  isUpdating: boolean;
};
export const Availability: FC<AvailabilityProps> = ({ isUpdating }) => {
  const [bookedAvailability, setBookedAvailability] = useState<YearBookedAvailability>();
  const [prices, setPrices] = useState<Price[]>();
  const [updatedYears, setUpdatedYears] = useState<number[]>([]);
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>({ success: false, error: false });
  const { data, error, isLoading } = useAvailabilityQuery();

  const updateAvailability = (day: number, month: number, year: number): void => {
    const updatedAvailability = updateTheAvailability(bookedAvailability as YearBookedAvailability, day, month, year);
    const uniqueYears = new Set([...updatedYears, year]);
    setUpdatedYears(Array.from(uniqueYears));
    setBookedAvailability(updatedAvailability);
  };

  useEffect(() => {
    if (data) {
      setBookedAvailability(data.bookings);
      setPrices(data.prices);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner />;
  }
  if (error || error || !bookedAvailability || !prices) {
    return <div>ERROR</div>;
  }
  const date = new Date();
  const currentMonth = date.getMonth();
  const year = currentMonth > 9 ? date.getFullYear() + 1 : date.getFullYear();
  const startMonth = currentMonth > 3 && currentMonth < 10 ? currentMonth : 4;
  const nextYear = year + 1;

  return (
    <PageContainer>
      <CommonPageContent>
        <Prices prices={prices} />
      </CommonPageContent>
      <CommonPageContent data-testid="availability">
        <CommonPageHeader>Availability</CommonPageHeader>
        {updateStatus.success && <div>Success</div>}
        {updateStatus.error && <div>Oops something failed</div>}
        <CSS.AvailabilityKeys>
          <CSS.DayKey bgcolor={getColor('u')}>Unavailable</CSS.DayKey>
          <CSS.DayKey bgcolor={getColor('a')}>Available</CSS.DayKey>
          <CSS.DayKey bgcolor={getColor('b')}>Booked</CSS.DayKey>
        </CSS.AvailabilityKeys>
        <div>
          <h3>{year} Season</h3>
        </div>
        <CalendarCarousel uniqueId={`${year}`} startMonth={startMonth}>
          {monthsOrder.map((month: number) => {
            const thisMonthsBookedAvailability = getBookedAvailability(month, year, bookedAvailability);
            return (
              <CalendarMonth
                key={`${month}-${year}`}
                daysBooked={thisMonthsBookedAvailability}
                month={month}
                year={year}
                setAvailability={isUpdating ? updateAvailability : undefined}
              />
            );
          })}
        </CalendarCarousel>
        <div>
          <h3>{nextYear} Season</h3>
        </div>
        <CalendarCarousel uniqueId={`${nextYear}`} startMonth={4}>
          {monthsOrder.map((month: number) => {
            const thisMonthsBookedAvailability = getBookedAvailability(month, nextYear, bookedAvailability);
            return (
              <CalendarMonth
                key={`${month}-${nextYear}`}
                daysBooked={thisMonthsBookedAvailability}
                month={month}
                year={nextYear}
                setAvailability={isUpdating ? updateAvailability : undefined}
              />
            );
          })}
        </CalendarCarousel>
        {isUpdating && <UpdateButton bookedAvailability={bookedAvailability} updatedYears={updatedYears} setUpdateStatus={setUpdateStatus} />}
      </CommonPageContent>
    </PageContainer>
  );
};
