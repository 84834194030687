import React, { FC } from 'react';

import { GuestInfoContainer } from '../guest-info-container';

export const ThingsToDo: FC = () => {
  return (
    <GuestInfoContainer>
      <h3>Things To Do</h3>
      <h4>Beach Clubs</h4>
      <p>Kalkan has a number of beach clubs, most of which can be reached by water taxi from the harbour.</p>
      <p>During the busy months, you will need to get there quite early to guarantee a sun bed! (by around 10/10.30am). </p>
      <p>
        All the beachclubs are all set around the bay and have either a paved platform or decked terraces and offer everything from swimming and sunbathing to water sports and
        restaurant/bar facilities (waiter service).
      </p>
      <p>
        They all have access into the sea (usually via steps). You can spend a whole day at a beach club and it is the perfect way to soak up the sun and the fantastic views of
        Kalkan.
      </p>
      <p>
        On arrival, all beach clubs will open a 'tab' for you and any food/drink, plus a charge for sun bed/parasol use will be added to this, you will just need to settle the bill
        as you leave.
      </p>
      <p>
        Most beach clubs provide free water taxi's. They are all worth a visit and everyone has their favourite. We tend to stick to Indigo and Denizim. At present, Zest is the
        only one you have to pre-book, the others you can just turn up.
      </p>
      <h4>Kalkan Market</h4>
      <p>
        Kalkan's popular market is on a Thursday where you can pick up all your fake goods, along with a good selection of fruit and vegetables etc. The market is a good place to
        practice your haggling!
      </p>
      <p>
        Akbel market is more of a 'local' market where you can buy lots of fruit, veg, fish, olives, cheeses etc. A small market up in the local village, only a 5 minute cab ride
        away. Worth a visit if you want to stock up on food. This market is on a Sunday.
      </p>
      <h4>Turkish Bath</h4>
      <p>Why not treat yourself to a Turkish Bath (Hammam). There are many spa's around Kalkan which offer these.</p>
      <p>It's best to have a Turkish Bath at the start of your holiday as this will get rid of your dead skin and create a good base for your sun tan.</p>

      <p>
        We tend to go to The Oasis Hotel as it's just around the corner and they have a lovely spa area, but the Elixir Hotel on Kalamar Road is very popular, where Nur and Hakan
        (man and wife team) will look after you.
      </p>
      <p>Also Arcadia Spa Centre is a lovely spa and they will send a taxi for you, free of charge. </p>
    </GuestInfoContainer>
  );
};
