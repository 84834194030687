import styled from 'styled-components';

import { FlexRowCentred, devices } from '../common-styles/common-styles';

export const MonthWrapper = styled.div`
  outline: none;
`;

export const MonthContainer = styled.table`
  margin-left: 3px;
  margin-right: 3px;
  @media ${devices.tablet} {
    margin-left: 8px;
    margin-right: 8px;
  }
  /* border: 1px solid #5890ad; */
  background-color: white;
  outline: none;
  color: white;

  th {
    /* border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white; */
    padding: 5px 8px 5px 0;
    text-align: center;
    background-color: #6c8bb9;
    height: 32px;
  }

  td {
    /* border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white; */
    /* padding: 5px 8px 5px 0; */
    /* text-align: right; */
    font-size: 14px;
    height: 23px;
    /* width: 45px; */
    min-width: 19px;
    min-height: 32px;
    @media ${devices.tablet} {
      height: 30px;
      min-width: 28px;
      min-height: 38px;
    }
    @media ${devices.desktop} {
      height: 30px;
      min-width: 32px;
      min-height: 38px;
    }
  }
`;

export const DayKey = styled.div<{ bgcolor?: string | null }>`
  min-width: 5rem;
  margin: 5px;
  padding: 7px 10px;
  color: white;
  background-color: ${(props) => props.bgcolor || '#b9b9b9'};
  text-align: center;
`;

export const Day = styled.td<{ bgcolor?: string | null }>`
  color: white;
  background-color: ${(props) => props.bgcolor || '#b9b9b9'};
  text-align: right;
  padding-right: 10px;
`;

export const DayTitle = styled.td`
  color: black;
  background-color: #b9b9b9;
  text-align: center;
  padding: 0;
  min-width: 34px;
  min-height: 38px;
`;

export const AvailabilityKeys = styled(FlexRowCentred)`
  background: white;
  width: fit-content;
  border-radius: 3px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
`;

export const AvailabilityKey = styled.div`
  display: inline-block;
  padding: 5px 10px;
  margin: 3px;
  width: 95px;
  text-align: center;

  @include tablet {
    margin: 10px;
    width: 100px;
  }
`;

export const AvailabilityUnavailable = styled.div`
  display: inline-block;
  width: 200px;
  height: 30px;
`;

export const CarouselItem = styled.div`
  display: inline-block;
  touch-action: pan-x;
`;

export const UpdateButtonWrapper = styled.div`
  width: 20rem;
  margin: 1rem auto;
  background: white;
  border-radius: 3px;
`;
