import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { PageContainer } from '../common-styles/common-styles';

import * as CSS from './home.styles';

export const HomePage: FC = () => (
  <PageContainer>
    <CSS.HomeContent>An exceptionally spacious, fully air conditioned 3 bedroom, 3 bathroom duplex apartment, comfortably sleeping 6 adults.</CSS.HomeContent>
    <CSS.HomeFooter data-testid="home-footer">
      <Link to="/location">
        <CSS.HomeContent>A private apartment in Kalkan, Turkey. For more details click here...</CSS.HomeContent>
      </Link>
    </CSS.HomeFooter>
  </PageContainer>
);
