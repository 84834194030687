import React, { FC } from 'react';

import { GuestInfoContainer } from '../guest-info-container';

export const Shopping: FC = () => {
  return (
    <GuestInfoContainer>
      <h3>Shopping</h3>
      <h4>Supermarkets</h4>
      <p>There are plenty of supermarkets close to the apartment.</p>
      <p>
        Anka supermarket just by Deniz Bank about 5/7 minutes walk away. Turn right out of the apartment, then turn left at the end of the swimming pool - carry on walking STRAIGHT
        up the road, past the Emir apartments, until you get to the end of the road and Deniz Bank is on your right. The Anka supermarket is next door to the bank around the
        corner.
      </p>
      <p>
        There is a closer, smaller supermarket on the Kalamar Road, just 3 minutes walk away from Fabio. Turn right out of the apartment, then left, then turn right DOWN the hill,
        then at the bottom of the hill/junction, turn left and the shop is a little way down on the left hand side, by the chemist.
      </p>
      <p>
        There is also a medium size Migros on the Kalamar Road, just 5 minutes walk away from Fabio. Turn right out of the apartment, then left, then turn right DOWN the hill, then
        at the bottom of the hill/junction, turn right and Migros is approxoimately 150 metres on the right.
      </p>
      <p>
        2M is a fantastic little shop down by Kaya Restaurant which sells spit roast chickens and a vast array of lovely things including meze and dips etc! This little shop is
        like a tardis and well worth a visit.
      </p>
      <p>
        Also, just by Anka Supermarket (underneath) there is a lovely bakers where you can get your freshly baked bread daily. They also sell lovely Turkish cakes including freshly
        baked baklava.
      </p>

      <h4>Genuine Fakes!</h4>
      <p>
        Kalkan has amazing quality fake designer handbags - not particularly cheap, but almost like the real thing. There are also a few shops which sell fake t-shirts/polo shirts
        etc; the quality of these are great and you can pick them up very cheap. We recommend the "London Outlet" (underneath The View restaurant) for these.
      </p>
      <h4>Jewellers</h4>
      <p>
        There are also a lot of jewellery shops in Kalkan - they do good quality jewellery including fake watches. They will also make you pieces of jewellery to your design -
        handy if you have any old gold you want melted down.
      </p>
    </GuestInfoContainer>
  );
};
