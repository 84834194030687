import React, { FC } from 'react';
import { useAvailabilityMutation } from '../../api/availability';

import * as CSS from './availability.styles';
import { CommonButton } from '../common-styles/common-styles';
import { UpdateStatus, YearBookedAvailability } from './availability';

type UpdateButtonProps = {
  updatedYears: number[];
  bookedAvailability: YearBookedAvailability;
  setUpdateStatus: (updateStatus: UpdateStatus) => void;
};

export const UpdateButton: FC<UpdateButtonProps> = ({ updatedYears, bookedAvailability, setUpdateStatus }) => {
  const mutation = useAvailabilityMutation();
  const saveAvailability = () => {
    updatedYears.forEach((year) => {
      const dataToSave = bookedAvailability ? bookedAvailability[year] : {};
      if (dataToSave) {
        mutation.mutate(
          { data: dataToSave, year },
          {
            onSuccess: (data) => {
              setUpdateStatus({ success: true, error: false });
              console.log('<><><><SUCCESS><><>><', data);
            },
            onError: (error) => {
              setUpdateStatus({ success: false, error: true });
              console.log('<><><>ERROR<><><><', error);
            },
          }
        );
      }
    });
  };
  return (
    <CSS.UpdateButtonWrapper>
      <CommonButton onClick={() => saveAvailability()} disabled={!updatedYears.length}>
        Save
      </CommonButton>
    </CSS.UpdateButtonWrapper>
  );
};
