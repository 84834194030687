import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HomePage } from './components/home/home-page';
import ContactUs from './components/contact-us/contact-us';
import Location from './components/location';
// import Availability from './containers/availability';
// import AdminAvailability from './containers/admin-availability';
import Gallery from './components/gallery/gallery';
import { Availability } from './components/availability/availability';
import { Admin } from './components/admin/admin';
import { Apartment } from './components/guest-info/subjects/apartment';
import { Welcome } from './components/guest-info/subjects/welcome';
import { Contacts } from './components/guest-info/subjects/contacts';
import { Restaurants } from './components/guest-info/subjects/restaurants';
import { ThingsToDo } from './components/guest-info/subjects/things-to-do';
import { Shopping } from './components/guest-info/subjects/shopping';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <div>Error Page (to be done)</div>,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: '/contact-us',
        element: <ContactUs />,
      },
      {
        path: '/location',
        element: <Location />,
      },
      {
        path: '/gallery',
        element: <Gallery />,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
      {
        path: '/availability',
        element: <Availability isUpdating={false} />,
      },
      {
        path: '/guest-info',
        element: <Welcome />,
      },
      {
        path: '/guest-info/contacts',
        element: <Contacts />,
      },
      {
        path: '/guest-info/apartment',
        element: <Apartment />,
      },
      {
        path: '/guest-info/restaurants',
        element: <Restaurants />,
      },
      {
        path: '/guest-info/shopping',
        element: <Shopping />,
      },
      {
        path: '/guest-info/things-to-do',
        element: <ThingsToDo />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
