import React, { FC, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexRowCentred } from '../common-styles/common-styles';

import { devices } from '../common-styles/common-styles';

const Outer = styled.div`
  position: relative;
  overflow-y: visible;
  overflow-x: hidden;
  height: auto;
  width: 100%;
  margin: 0 7px;
  @media ${devices.tablet} {
    margin: 0 24px;
  }
  @media ${devices.desktop} {
    margin: 0 15px;
  }
`;
const Inner = styled.div`
  /* border: 1px solid red; */
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  width: fit-content;
  height: auto;
  transition: left 1s;
  white-space: nowrap;
`;

const Button = styled.button`
  height: 35px;
  border-radius: 50%;
  border: none;
  width: 40px;
  font-size: 20px;
`;
const showPositions = (uniqueId: string) => {
  const outer = document.getElementById(`outer-${uniqueId}`);
  const inner = document.getElementById(`inner-${uniqueId}`);
  if (inner && outer) {
    console.log('********************************');
    console.log('<Outer>', outer.getBoundingClientRect().left, outer.getBoundingClientRect().right);
    console.log('<Inner>', inner.getBoundingClientRect().left, inner.getBoundingClientRect().right);
    // console.log('<Items>', firstChild?.getBoundingClientRect().left, lastChild?.getBoundingClientRect().right);
    console.log('********************************');

    // console.log('<Items<first.left last.right<', inner.firstElementChild?.getBoundingClientRect().x, inner.lastElementChild?.getBoundingClientRect().right);
  }
};
const convertToNumber = (attribute: string) => {
  return Number(attribute.substring(0, attribute.length - 2));
};

type CarouselProps = {
  startMonth?: number;
  children: React.ReactNode;
  uniqueId: string;
};

const getFirstChildWidth = (uniqueId: string): number => {
  const inner = document.getElementById(`inner-${uniqueId}`);
  if (inner) {
    const firstChild = inner.firstElementChild;
    if (firstChild) {
      const computedStyle = window.getComputedStyle(firstChild);
      const marginLeft = computedStyle.marginLeft;
      const marginRight = computedStyle.marginRight;
      const borderLeft = computedStyle.borderLeftWidth;
      const borderRight = computedStyle.borderRightWidth;

      const width = computedStyle.width;
      return convertToNumber(width) + convertToNumber(marginLeft) + convertToNumber(marginRight) + convertToNumber(borderLeft) + convertToNumber(borderRight);
    }
  }
  return 10;
};

export const CalendarCarousel: FC<CarouselProps> = ({ uniqueId, startMonth = 0, children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [leftDisabled, setLeftDisabled] = useState(false);
  const [rightDisabled, setRightDisabled] = useState(false);
  const [outerWidth, setOuterWidth] = useState<string>('100%');
  const [innerLeft, setInnerLeft] = useState<number>(0);
  const [firstVisibleTile, setFirstVisibleTile] = useState(startMonth);

  const calcNewLeft = (scroll: string): void => {
    const outer = document.getElementById(`outer-${uniqueId}`);
    const inner = document.getElementById(`inner-${uniqueId}`);
    if (outer && inner) {
      const firstChild = inner.firstElementChild;
      if (firstChild) {
        const firstChildWidth = getFirstChildWidth(uniqueId);
        const newLeft = scroll === 'left' ? innerLeft + firstChildWidth : innerLeft - firstChildWidth;
        const newFirstVisibleTile = scroll === 'left' ? firstVisibleTile - 1 : firstVisibleTile + 1;
        setFirstVisibleTile(newFirstVisibleTile);
        setLeftDisabled(newLeft >= 0);
        setRightDisabled(newFirstVisibleTile === 11);
        setInnerLeft(newLeft);
      }
    }
  };

  useLayoutEffect(() => {
    if (innerLeft === 0 && !hasLoaded) {
      const outer = document.getElementById(`outer-${uniqueId}`);
      const inner = document.getElementById(`inner-${uniqueId}`);
      if (outer && inner) {
        const firstChildWith = getFirstChildWidth(uniqueId);
        const parent = outer.parentElement?.clientWidth ?? 1;
        const maxToShow = Math.floor(parent / firstChildWith);
        setOuterWidth(`${maxToShow * firstChildWith}px`);
      }
      if (startMonth !== 0) {
        const width = getFirstChildWidth(uniqueId);
        const newLeft = -1 * width * startMonth;
        setInnerLeft(newLeft);
      }
    }
    setHasLoaded(true);
  }, [startMonth, innerLeft, hasLoaded, uniqueId]);

  return (
    <div>
      <FlexRowCentred>
        <Button onClick={() => calcNewLeft('left')} disabled={leftDisabled}>
          {'<'}
        </Button>
        <Outer id={`outer-${uniqueId}`} data-testid={`outer-${uniqueId}`} style={{ width: `${outerWidth}px` }}>
          <Inner id={`inner-${uniqueId}`} data-testid={`inner-${uniqueId}`} style={{ left: `${innerLeft}px` }}>
            {children}
          </Inner>
        </Outer>
        <Button onClick={() => calcNewLeft('right')} disabled={rightDisabled}>
          {'>'}
        </Button>
      </FlexRowCentred>
    </div>
  );
};
